// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

let apiUrl = 'http://dominica.dotgov.uk/api/';
let hideLogin = false;
let debug = {
  logs: true,
};
let enableRegistration = true;


export const environment = {
  enableRegistration,
  production: false,
  apiUrl,
  hideLogin,
  debug,
};
