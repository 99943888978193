import { Injectable } from '@angular/core';
import { IAppConfig } from '../models/app-config';

@Injectable()
export class AppConfig {

  static settings: IAppConfig = {} as any;

  static load() {
    const jsonFile = `assets/appsettings.json`;
    return new Promise<void>((resolve, reject) => {
      fetch(jsonFile)
        .then(response => response.json())
        .then((data) => {
          AppConfig.settings = data as IAppConfig;
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
