import { AppConfig } from '@shared/services/settings.service';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./assets/pdf.worker.min.js').then(function() {});
}
// This approach causes a warning from angular-cli please ignore it
AppConfig.load()
  .then(() => import('./app/app.module'))
  .then(m => {
    platformBrowserDynamic()
      .bootstrapModule(m.AppModule)
      .catch(err => console.log(err));
  });
